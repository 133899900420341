<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="名称">
            <a-input v-model="form['customer_name']" />
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="类型">
            <a-select v-model="form.type" allow-clear>
              <a-select-option value="1">客户</a-select-option>
              <a-select-option value="2">供应商</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="部门">
            <a-select showSearch allowClear style="width: 100%" v-model="form['department']">
              <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="是否公司内">
            <a-select showSearch allowClear style="width: 100%" v-model="form.is_company">
              <a-select-option :value="1">公司外</a-select-option>
              <a-select-option :value="2">公司内</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="创建时间">
            <a-range-picker @change="onDateChange"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" class="margin-right10" @click="delRecord('all')">删除</a-button>
        <a-button class="mergin-right10" icon="upload" @click="download">导出</a-button>
        <!-- <a-upload
          :customRequest="handleCustomerImport"
          :showUploadList="false"
        >
          <a-button type="primary">导入客户</a-button>
        </a-upload> -->
        <a-alert style="margin-top:10px" type="error" message="标记为红色的信息需要在提交开票付款申请时提供SAP代码给到财商" banner />
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :customRow="
        (record, index) => {
          return {
            style: {
              background: record.is_valid === 1 ? '#ffe8e9' : ''
            }
          }
        }
      "
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord('row', record)">删除</a>
      </div>
      <div slot="bankInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <span style="color: #ffb34f;">{{ item.bank_name }}</span> : <span>{{ item.account }}</span>
        </div>
      </div>
      <div slot="allowInvoiceSlot" slot-scope="text">
        <a-tag :color="text === 'N'?'red':'green'">{{text === 'N'?'否':'是'}}</a-tag>
      </div>
    </a-table>
    <a-modal :width="650" v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="formModel"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="类型" prop="type">
          <a-select v-model="formModel.type">
            <a-select-option :value="1">客户</a-select-option>
            <a-select-option :value="2">供应商</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门" prop="account">
          <a-select mode="multiple" allowClear placeholder="请选择部门" v-model="formModel.department">
            <a-select-option v-for="item in departmentList" :key="item.value">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否公司内">
          <a-select v-model="formModel.is_company">
            <a-select-option :value="1">公司外</a-select-option>
            <a-select-option :value="2">公司内</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否国有">
          <a-select v-model="formModel.is_nation">
            <a-select-option value="Y">是</a-select-option>
            <a-select-option value="N">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="SAP代码" prop="sap_code">
          <a-input v-model="formModel.sap_code" />
        </a-form-model-item> -->
        <a-form-model-item label="公司编码" prop="company_code">
          <a-input v-model="formModel.company_code" />
        </a-form-model-item>
        <a-form-model-item label="关联方属性" prop="link_attr">
          <a-input v-model="formModel.link_attr" />
        </a-form-model-item>
        <a-form-model-item label="中文名称" prop="customer_cn_name">
          <a-input v-model.trim="formModel.customer_cn_name" />
        </a-form-model-item>
        <a-form-model-item label="英文名称" prop="customer_en_name">
          <a-input v-model.trim="formModel.customer_en_name" />
        </a-form-model-item>
        <a-form-model-item label="是否允许开票" prop="is_allow_invoice">
          <a-select v-model="formModel.is_allow_invoice">
            <a-select-option value="Y">是</a-select-option>
            <a-select-option value="N">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="税号" prop="tax_num">
          <a-input v-model="formModel.tax_num" />
        </a-form-model-item>
        <a-form-model-item label="账户组" prop="account_group">
          <a-input v-model="formModel.account_group" />
        </a-form-model-item>
        <a-form-model-item label="快速搜索项" prop="search_key">
          <a-input v-model="formModel.search_key" />
        </a-form-model-item>
        <a-form-model-item label="街道" prop="street">
          <a-input v-model="formModel.street" />
        </a-form-model-item>
        <a-form-model-item label="电话" prop="phone">
          <a-input v-model="formModel.phone"/>
        </a-form-model-item>
        <a-form-model-item label="邮编" prop="post_code">
          <a-input v-model="formModel.post_code" />
        </a-form-model-item>
        <a-form-model-item label="城市" prop="city">
          <a-input v-model="formModel.city" />
        </a-form-model-item>
        <a-form-model-item label="国家" prop="country">
          <a-input v-model="formModel.country" />
        </a-form-model-item>
        <a-form-model-item label="语言" prop="language">
          <a-input v-model="formModel.language" />
        </a-form-model-item>
        <a-form-model-item label="排序码" prop="sort_code">
          <a-input v-model="formModel.sort_code" />
        </a-form-model-item>
        <a-form-model-item label="银行名称-账户" prop="bankName">
          <a-input-group compact v-for="(item,index) in formModel.bank_account" :key="index">
            <a-input v-model="item.bank_name" style="width: 40%" />
            <a-input v-model="item.account" style="width: 40%" />
            <a-button type="danger" style="width: 20%" @click="delBank(index)">删除</a-button>
          </a-input-group>
        </a-form-model-item>
        <a-form-model-item v-bind="formItemLayoutWithOutLabel">
          <a-button type="dashed" style="width: 60%" @click="addBank">
            <a-icon type="plus" /> 添加银行信息
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import {
  getCommonPage,
  saveCustomer,
  deleteCommonInfo,
  importTemp,
  exportCustomerInfoList,
  downloadExcel,
  queryCustomerInfoList
} from '@/api/common'
export default {
  data() {
    return {
      labelCol: { span: 5 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      form: {
        company_code: '',
        type: null
      },
      formModel: {
        type: null,
        company_code: '',
        sap_code: '',
        link_attr: '',
        customer_cn_name: '',
        customer_en_name: '',
        account_group: '',
        search_key: '',
        street: '',
        post_code: '',
        city: '',
        country: '',
        language: '',
        sort_code: '',
        is_allow_invoice: 'Y',
        tax_num: '',
        bank_account: [
          {
            bank_name: '',
            account: ''
          }
        ],
        is_company: 1,
        is_nation: null
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }],
        // company_code: [{ required: true, message: '请输入公司代码', trigger: 'blur' }],
        // sap_code: [{ required: true, message: '请输入客户SAP编码', trigger: 'blur' }],
        // link_attr: [{ required: true, message: '请输入关联方属性', trigger: 'blur' }],
        customer_cn_name: [{ required: true, message: '请输入客户中文名称', trigger: 'blur' }],
        tax_num: [{ required: true, message: '请输入税号', trigger: 'blur' }]
        // customer_en_name: [{ required: true, message: '请输入客户英文名称', trigger: 'blur' }],
        // account_group: [{ required: true, message: '请输入客户账户组', trigger: 'blur' }],
        // search_key: [{ required: true, message: '请输入快速搜索项', trigger: 'blur' }],
        // street: [{ required: true, message: '请输入街道', trigger: 'blur' }],
        // post_code: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
        // city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        // country: [{ required: true, message: '请输入国家', trigger: 'blur' }],
        // language: [{ required: true, message: '请输入语言', trigger: 'blur' }],
        // head_account: [{ required: true, message: '请输入总部账号', trigger: 'blur' }],
        // trade_partner: [{ required: true, message: '请输入贸易伙伴', trigger: 'blur' }],
        // cash_group: [{ required: true, message: '请输入现金管理组', trigger: 'blur' }],
        // control_subject: [{ required: true, message: '请输入统驭科目', trigger: 'blur' }],
        // sort_code: [{ required: true, message: '请输入排序码', trigger: 'blur' }],
        // pay_condition: [{ required: true, message: '请输入支付条件', trigger: 'blur' }],
        // origin_customer_code: [{ required: true, message: '请输入原系统客户编码', trigger: 'blur' }]
      },
      options: [
        { name: '集团内客户', value: 0 },
        { name: '集团外关联客户', value: 1 },
        { name: '非经营性客户', value: 2 }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            return text === 1 ? '客户' : '供应商';
          }
        },
        {
          title: '是否公司内',
          dataIndex: 'is_company',
          customRender: item => {
            return item === 1 ? '公司外' : '公司内';
          }
        },
        {
          title: 'SAP代码',
          dataIndex: 'sap_code'
        },
        {
          title: '公司编码',
          dataIndex: 'company_code'
        },
        {
          title: '关联方属性',
          dataIndex: 'link_attr'
        },
        {
          title: '中文名称',
          dataIndex: 'customer_cn_name'
        },
        {
          title: '英文名称',
          dataIndex: 'customer_en_name'
        },
        {
          title: '账户组',
          dataIndex: 'account_group'
        },
        {
          title: '是否国有',
          dataIndex: 'is_nation',
          customRender: (text) => {
            return text ? (text === 'Y' ? '是' : '否') : ''
          }
        },
        {
          title: '快速搜索项',
          dataIndex: 'search_key'
        },
        {
          title: '街道',
          dataIndex: 'street'
        },
        {
          title: '电话',
          dataIndex: 'phone'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '城市',
          dataIndex: 'city'
        },
        {
          title: '国家',
          dataIndex: 'country'
        },
        {
          title: '语言',
          dataIndex: 'language'
        },
        {
          title: '排序码',
          dataIndex: 'sort_code'
        },
        {
          title: '允许开票',
          dataIndex: 'is_allow_invoice',
          scopedSlots: { customRender: 'allowInvoiceSlot' }
        },
        {
          title: '税号',
          dataIndex: 'tax_num'
        },
        {
          title: '银行账户',
          dataIndex: 'bank_account',
          scopedSlots: { customRender: 'bankInfo' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增客户供应商信息',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'customer_info',
      // 客户供应商部门数据
      departmentList: [
        { value: 1, name: '仓储部' },
        { value: 2, name: '国贸部' },
        { value: 3, name: '总经办' },
        { value: 5, name: '财商部' },
        { value: 6, name: '堆场' },
        { value: 7, name: '客服部' },
        { value: 8, name: '进销存' },
        { value: 9, name: '综合部' }
      ]
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.form['start_date'] = startDate
        this.form['end_date'] = endDate
      } else {
        this.form['start_date'] = null
        this.form['end_date'] = null
      }
    },
    download() {
      exportCustomerInfoList(this.form).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res };
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    },
    getCommonPage,
    deleteCommonInfo,
    getInfo(param) {
      queryCustomerInfoList(this.moduleName, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    newRecord() {
      this.modelTitle = '新增客户供应商信息'
      this.visible = true
      this.formModel = {
        type: null,
        company_code: '',
        sap_code: '',
        link_attr: '',
        customer_cn_name: '',
        customer_en_name: '',
        account_group: '',
        search_key: '',
        street: '',
        post_code: '',
        city: '',
        country: '',
        language: '',
        sort_code: '',
        tax_num: '',
        is_allow_invoice: 'Y',
        bank_account: [
          {
            bank_name: '',
            account: ''
          }
        ],
        is_company: 1
      }
    },
    delRecord(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo(this.moduleName, { ids: _ids }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    modifyRecord(record) {
      this.modelTitle = '修改客户供应商信息'
      this.visible = true
      this.formModel = Object.assign({}, record)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSave() {
      this.$refs.formModel.validate(valid => {
        if (valid) {
          this.saveInfo()
        } else {
          return false
        }
      })
    },
    saveInfo() {
      saveCustomer(this.formModel).then(res => {
        if (res) {
          this.$message.success('保存成功!')
          const param = Object.assign({}, this.form)
          param.page = this.pagination.current
          param.pageSize = this.pagination.pageSize
          this.getInfo(param)
          this.visible = false
        } else {
          this.$message.error('保存失败!')
        }
      })
    },
    handleCancel(e) {
      this.$refs.formModel.resetFields()
      this.visible = false
    },
    addBank() {
      this.formModel.bank_account.push({
        bank_name: '',
        account: ''
      })
    },
    delBank(index) {
      this.formModel.bank_account.splice(index, 1)
    },
    handleCustomerImport(obj) {
      var formData = new FormData()
      formData.append('uploadFile', obj.file)
      importTemp(formData).then(v => {
        obj.onSuccess()
        this.$notification['success']({
          message: '提示',
          description: '导入成功'
        })
        this.searchInfo()
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
